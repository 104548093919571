import React, { useState, useEffect, lazy, Suspense, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { HeroImageRight } from '../components/General/HeroImageRight';
import { HeroVideo } from '../components/General/HeroVideo';
import { Hero } from '../components/General/Hero';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { ProductFeatureBody } from '../components/ProductFeature/ProductFeatureBody';
import { FeatureFAQ } from '../components/ProductFeature/FeatureFAQ';
// import { Form } from '../components/ContactForm/Form';
import FeatureThumbnailContext from '../context/FeatureThumbnailContext';
import { ThumbnailFeatureModal } from '../components/General/ThumbnailFeatureModal';
import { FeatureModal } from '../components/Industry/SoftwareFeatures/FeatureModal';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	background: {
		background: theme.white,
	},
	heroVideo: {
		marginBottom: '2rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '3rem',
		},
	},
	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},
	formCont: {
		padding: '12rem 0 24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '8rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '5rem 0',
		},
	},
}));

export default function ProductFeature({ data: { productFeature }, location }) {
	const classes = useStyles();
	// const iosLogo = appLogos.edges[0];
	// const androidLogo = appLogos.edges[1];
	// const qbLogo = appLogos.edges[2];
	// const zapierLogo = appLogos.edges[3];
	// const shopifyLogo = appLogos.edges[4];

	//state for selected card for features
	const [selectedFeature, setSelectedFeature] = useState(null);

	const { modalOpen, setModalOpen, selectedThumbnail } = useContext(
		FeatureThumbnailContext
	);

	//feature list modal controls
	const [featureModalOpen, setFeatureModalOpen] = useState(false);

	useEffect(() => {
		setSelectedFeature(productFeature.featureSection[0]?._key);
	}, []);

	const {
		_id,
		metaTitle,
		metaDescription,
		marketoId,
		pardotUrl,
		contactForm,
		hero,
		heroImage,
		heroVideo,
		heroAlignCenter,
		formBgImage,
		resourceTitle,
		resources,
		_rawResourceBody,
		faq,
		faqTitle,
		cta,
		softwareFeatures,
		highlightCards,
		videoFallbackImage,
	} = productFeature;

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			{heroAlignCenter && heroVideo ? (
				<div className={classes.heroVideo}>
					<Hero hero={hero} feature fs video />
					<Container>
						<HeroVideo
							wistiaLink={heroVideo}
							button={hero.ctaButtonText || hero.ctaButtonLink}
							videoFallbackImage={videoFallbackImage}
              wavelytics={_id === '9c482bbe-2438-4465-8116-508d6dafdfc9' ? 'wavelytics' : null}
						/>
					</Container>
				</div>
			) : heroAlignCenter ? (
				<Hero hero={hero} feature fs _id={_id} />
			) : (
				<HeroImageRight
					_id={_id}
					hero={hero}
					heroImage={heroImage?.asset?.gatsbyImageData}
					feature
				/>
			)}
			<ProductFeatureBody
				body={productFeature}
				cta={cta}
				setFeatureModalOpen={setFeatureModalOpen}
				heroVideo={heroVideo}
			/>
			{/* <WaveUpSVG height='213' width='100%' fill='#fff' /> */}

			{faq.length ? (
				<>
					<WaveDownSVG height='213' width='100%' fill='#FFFFFF' />
					<div
						style={{
							background: '#f4f8ff',
							paddingBottom: '4rem',
							//marginBottom: '-2rem',
						}}>
						<Container>
							<FeatureFAQ header={faqTitle} faq={faq} />
						</Container>
					</div>
				</>
			) : null}

			{/* <div>
				<WaveDownSVG
					height='213'
					width='100%'
					fill={faq.length ? '#f4f8ff' : '#FFFFFF'}
				/>
			</div> */}

			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
					marginTop:
						_id === '1f17a466-ce5f-4485-a2b8-0453bae0235e' ? '4rem' : 0,
				}}>
				{!!selectedFeature ? (
					<Form
						// formId={marketoId}
						pardotUrl={pardotUrl}
						contactForm={contactForm}
						privacy
						modal={false}
						location={location}
					/>
				) : null}
			</div>
			{/* <WaveUpSVG height='213' width='100%' fill='#FFFFFF' /> */}
			<FeatureModal
				header='PestPac Lite Header'
				subheader='Learn More About PestPacLite'
				// modal={modal}
				accentColor='#002D5C'
				softwareFeatures={softwareFeatures}
				open={featureModalOpen}
				setFeatureModalOpen={setFeatureModalOpen}
				lite
			/>
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
					noWave
				/>
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
			<ThumbnailFeatureModal
				open={modalOpen}
				setFeatureModalOpen={setModalOpen}
				wistiaLink={selectedThumbnail}
				feature
			/>
		</>
	);
}

//"features/lawn-care-service-reviews/"

export const query = graphql`
	query ($slug: String!) {
		productFeature: sanityProductFeatures(slug: { current: { eq: $slug } }) {
			_id
			title
			metaTitle
			metaDescription
			slug {
				current
			}
			hero {
				_rawContent
				backgroundImage {
					asset {
						gatsbyImageData(placeholder: BLURRED, height: 100)
					}
				}
				mobileBackgroundImage {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaButtonText
				ctaButtonLink
				internalLink
			}
			heroImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			heroVideo
			videoFallbackImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			heroAlignCenter
			_rawOverviewContent
			overviewImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			featureHeader
			featureSection {
				_key
				title
				cardTitle
				cardFaIcon
				cardIconColor {
					hexValue
				}
				bodySubHeader
				bodyHeader
				image {
					featureImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
				}
				videoVariant
				androidLink
				iosLink
				qbLink
				# svgVariant
				thumbnailVideoUrl
				ctaText
				ctaLink
				_rawBodyCopy
				internalLink
			}
			cta {
				ctaHeader
				ctaSubheader
				ctaLink
				ctaText
				internalLink
				ctaBackground {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
			testimonialTitle
			testimonial {
				title
				header
				testimonialLogo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				company
				testimonialText
				nameAndTitle
				videoVariant
				image {
					asset {
						gatsbyImageData(
							fit: FILLMAX
							height: 450
							width: 775
							placeholder: BLURRED
						)
					}
				}
			}
			testimonialBackground {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			softwareFeatures {
				title
				comparisonHeader
				featureListCta
				logos {
					asset {
						gatsbyImageData
					}
				}
				alternateTextHeaders
				features {
					featureTitle
					featureDescription
					colOne
					colTwo
					comparisonText
					colThree
				}
				expandedListHeader
				expandedListSubheader
				expandText
				hideText
				softwareLogoAndDescription {
					softwareComparisonTitle
					logos {
						asset {
							gatsbyImageData(placeholder: BLURRED, height: 85)
						}
					}
				}
			}
			highlightCards {
				title
				icon
				header
				accentColor {
					hexValue
				}
				_rawContent
			}
			resourceTitle
			_rawResourceBody
			resources {
				title
				blurb
				image {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaText
				ctaLink
			}
			formBgImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			contactForm {
				header
				privacyPolicy
			}
			pardotUrl
			customerLogosHeader
			customerLogos {
				logo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
			featuredPartnersHeader
			featuredPartnerCards {
				featuredPartnerTitle
				featuredPartnerLogo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				featuredPartnerBody
				featuredPartnerLink
			}
			faqTitle
			faq {
				question
				_rawAnswer
			}
			caseStudy {
				ctaBackground {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaHeader
				ctaLink
				ctaSubheader
				ctaText
				internalLink
			}
		}
		appLogos: allSanityAppLogos {
			edges {
				node {
					appLogoTitle
					appLogo {
						asset {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`;
